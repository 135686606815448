import React, { useState } from "react";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import styles from "app.module.css";
import { Header } from "components/header";
import { Main } from "components/main";
import { Features } from "components/features";
import { Pricing } from "components/pricing";
import { Reviews } from "components/reviews";
import { Footer } from "components/footer";
import { PrivacyPolicy } from "components/privacy";
import { TermsAndConditions } from "components/terms";
import { ContactSection } from "components/contact";
import { Unsubscribe } from "components/unsubscribe";
import Documentation from "components/docs";
import { Partners } from "components/partners";

const MainLayout = () => (
  <>
    <Main />
    <Partners />
    <Features />
    <Reviews />
    <Pricing />
    <Footer />
  </>
);

export const App = () => {
  const [, setShowLogo] = useState(true);

  return (
    <div className={styles.pageWrapper}>
      {!window?.location?.pathname.startsWith("/docs/api") && <Header />}
      <BrowserRouter>
        <Routes>
          <Route path="/features" element={<Features />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/contact" element={<ContactSection />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/docs/*" element={<Documentation />} />
          <Route path="/unsubscription/:tracking_id/:email/:id" element={<Unsubscribe setShowLogo={setShowLogo} />} />
          <Route path="*" element={<MainLayout />} />
        </Routes>
        <Outlet />
      </BrowserRouter>
    </div>
  );
};
