import advantar from "assets/svg/brands/advantar-transparent.svg";
import consummate from "assets/images/brands/consummate.jpg";
import lagride from "assets/images/brands/lagride.webp";
import verivafrica from "assets/images/brands/verivafrica.png";
import gideon from "assets/images/brands/gideon.png";

export const allReviews = [
  {
    name: "Kayode",
    company: "Advantar Marketing",
    content: `Go-Mailer is an outstanding email marketing tool that has greatly impressed our team. The automation feature is incredibly valuable for our email campaigns. The pricing is also excellent for SMEs, offering great value for money.
      
      We've been particularly impressed with their highly responsive customer support team, always ready to support our needs.  It is a top-notch solution for businesses looking to improve their email marketing efforts.
      `,
    image: advantar,
  },
  {
    name: "Funmilayo",
    company: "Lagos Ride",
    content: `Go-Mailer has been a game-changer for our email marketing efforts! Its user-friendly interface, and reliable delivery have significantly improved our campaign performance. 
    
      Kudos to the entire team!`,
    image: lagride,
  },
  {
    name: "Eniola",
    company: "Consummate Traders",
    content: `Our experience with Go-Mailer has been outstanding. We've seen significant improvement and growth since we joined the platform.What truly sets Go-Mailer apart, however, is their unwavering commitment to customer satisfaction. 
    
      Their customer experience is top-notch, and they demonstrate a genuine willingness to listen to feedback and act on it. This level of dedication has made all the difference in our journey with Go-Mailer.
    `,
    image: consummate,
  },
  {
    name: "Omotayo",
    company: "VerivAfrica",
    content: `Go-Mailer has proven to be an exceptional platform for managing our bulk email campaigns. The user-friendly interface, combined with a wide range of features, simplifies the process of handling our email marketing efforts. A standout feature is the campaign cloning functionality, which allows for the quick creation and sending of similar emails with minimal effort.


    The support team at Go-Mailer is also outstanding, consistently providing quick and helpful responses to any inquiries.
        
    `,
    image: verivafrica,
  },
  {
    name: "Gideon",
    company: "Gideonoware.com",
    content: `I discovered Go-Mailer in 2022 while searching for an efficient email platform for my company, and soon began using it for my marketing agency. Go-Mailer stands out for their exceptional customer support and outstanding email deliverability.

    Their system is designed to deliver emails directly to the primary inbox 99% of the time, avoiding the promotions or spam folders - a crucial feature for effective email marketing. Their team also provides quick assistance with any challenges, ensuring customer satisfaction.    
    `,
    image: gideon,
  },
];
