import React, { useState } from "react";
import cx from "classnames";
import styles from "./main.module.css";
import generalStyle from "app.module.css";
import { EmailInput } from "elements/inputs";
import landing_image from "assets/images/landing-image.png";
import * as userService from "services/user";
import config from ".config";

export const Main = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = () => {
    userService.createContact(`email=${email}&tags=pre-registration`).then((response) => {
      const {
        data: { error },
      } = response;
      if (error) {
        return;
      }
      window.location.href = `${config.app_url}/register?email=${encodeURIComponent(email)}`;
    });
  };
  return (
    <main className={styles.main}>
      <div className={cx(generalStyle.gmContainer, styles.layout)}>
        <div className={styles.mainWrapper}>
          <div className={styles.mainText}>
            <div className={styles.headingTitle}>
              <h1>
                Email Marketing for High <span className={styles.blue}> Conversions</span>
                <span className={styles.orange}>.</span>
              </h1>
            </div>
            <div className={styles.tagline}>
              Achieve high deliverability and increase your conversions with our high-performance platform. Grow your
              business seamlessly and convert leads into high-paying customers. Perfect for businesses of all sizes.
            </div>
          </div>
          <div className={styles.emailInputContainer}>
            <EmailInput value={email} onInput={(value) => setEmail(value)} onSubmit={handleSubmit} />
          </div>
        </div>
        <div className={styles.landingImage}>
          <img src={landing_image} alt="Landing page dashboard" />
        </div>
      </div>
    </main>
  );
};
