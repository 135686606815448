import React from "react";
import config from ".config.js";
import transactionals from "assets/svg/features/transactional.svg";
import automation from "assets/svg/features/automata.svg";
import massmailing from "assets/svg/features/mass-email.svg";
import { Feature } from "elements/feature";
import styles from "./features.module.css";
import generalStyle from "app.module.css";
import { Icon } from "assets/icons";

const features = [
  {
    icon: massmailing,
    tagline: "Mass Mailing",
    title: "Reach Every Customer, Every Time - with Just One Click.",
    description: `Increase your brand loyalty and conversions.
    
    Craft highly relevant and personalized email campaigns, building stronger relationships with your customers and driving conversions effortlessly.`,
    bgColor: "#FFBF6033",
    color: "#FFBF60",
    href: "https://app.go-mailer.com/register",
    cta: "Get Started",
  },
  {
    icon: transactionals,
    tagline: "TRANSACTIONAL EMAILS",
    title: "Respond to Your Customer Actions in Real-Time",
    description: `Enjoy timely responses to your customers. 
      
      Deliver highly relevant and personalized messages at every touchpoint in your customer's journey, driving higher engagement and relevant communications.`,
    bgColor: "#2A99FF33",
    color: "#2A99FF",
    href: "https://app.go-mailer.com/register",
    cta: "Get Started",
    direction: "rtl",
  },
  {
    icon: automation,
    tagline: "AUTOMATION",
    title: "Save Time & Boost Conversions 10x Faster",
    description: `Transform leads into loyal customers through perfectly timed, automated campaigns. 
      
      Respond to customer actions in real-time, delivering the right message at the right time for maximized conversions and revenue. .`,
    bgColor: "#66B10733",
    color: "#66B107",
    href: "https://app.go-mailer.com/register",
    cta: "Get Started",
  },
];

const featuresData = [
  {
    title: "Unlimited Subscribers For Unlimited Growth",
    description: `Take full control of your brand's growth with unlimited subscriber storage.
      
    You can now run your Ads and leads generation strategies without worrying about exceeding subscriber limits or expensive paywalls.
    `,
    url: "https://go-mailer.blog/advanced-subscriber-management-system-for-high-engagement/#unlimited-subscriber-storage",
  },
  {
    title: "Advanced Segmentation For Targeted Messaging",
    description: `Increase your conversions by sending the right message to the right customer segment.
    
    Advanced criteria-based segmentation enables you to split your customer base into fine-grained segments for more targeted and relatable messaging.
    `,
    url: "https://go-mailer.blog/advanced-subscriber-management-system-for-high-engagement/#unlimited-audience-segmentation",
  },
  {
    title: "Subscriber Synchronization And Attribution",
    description: `Collect, store, and utilize all the customer data you need for success.
    
    With simple and seamless APIs, you can keep your subscriber list updated with your applications and store all the data that your strategy needs.
    `,
    url: "https://go-mailer.blog/advanced-subscriber-management-system-for-high-engagement/#advanced-subscriber-synchronization",
  },
  {
    title: "Advanced Performance Reports For Refined Strategies",
    description: `Position your brand for greater success with data driven strategies.
    
    Gain better insights into your marketing activity to craft clearer and more informed strategy for improved results.
    `,
    url: "https://go-mailer.blog/advanced-performance-reporting/",
  },
  {
    title: "Flexible Payments For Adaptable Marketing",
    description: `Adapt your marketing activity to fixed budgets and changing economic trends.
    
    Maximize your outcomes by taking advantage of usage-based payments or subscription models depending on your strategy or budgetary constraints.
    `,
    url: "https://go-mailer.blog/about-go-mailer-pricing-options/",
  },
  {
    title: "Free Email Validations",
    description: `Maintain a high sender reputation for high open rates and conversions.
    
    Your subscriber list is continuously validated to minimize bounce rates and to ensure that your emails get delivered to their inboxes.
    `,
    url: "https://go-mailer.blog/advanced-subscriber-management-system-for-high-engagement/#free-email-validations",
  },
];

const handleSignUpAction = () => {
  window.location = `${config.app_url}/register`;
};

export const Features = () => {
  return (
    <section id="features" className={styles.features}>
      <div className={generalStyle.gmContainer}>
        <div className={styles.wrapper}>
          <div className={styles.featureList}>
            {features.map((feature, index) => (
              <Feature
                key={index}
                {...feature}
                action={
                  <a href={`${config.app_url}/register`} onClick={handleSignUpAction}>
                    {feature.action}
                  </a>
                }
              />
            ))}
          </div>
          <div className={styles.otherFeatures}>
            {featuresData.map((feature, index) => (
              <div key={index} className={styles.featureBox}>
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
                <a href={feature.url}>
                  <span>Learn More </span>
                  <Icon className={styles.featureBoxIcon} name="arrow_right_flat" />
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
